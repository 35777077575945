import React from "react";
import { StepsStateMachineProvider } from "./StepsStateMachineConext";
import StepsWrapper from "./StepsWrapper/StepsWrapper";
import "./CreateTokenSteps.scss";

const CreateTokenSteps = () => {
  return (
    <StepsStateMachineProvider>
      <StepsWrapper />
    </StepsStateMachineProvider>
  );
};

export default CreateTokenSteps;
