import * as Crypto from "@waves/ts-lib-crypto";
import { BLOCKCHAIN_CHAIN_ID, BLOCKCHAIN_NODE_URL } from "@config/config";
import { FetchBalanceResponses } from "@hooks/useFetch";

type FetchMethod = "GET" | "POST" | "PUT" | "DELETE";

export class BlockchainNodeClient {
  private static async request<T>(path: string, method: FetchMethod): Promise<T> {
    const requestUrl = `${BLOCKCHAIN_NODE_URL}${path}`;
    const response = await fetch(requestUrl, { method });
    const text = await response.text();
    const parsed = JSON.parse(text);
    return parsed;
  }

  static async fetchBalance(seed: string): Promise<FetchBalanceResponses> {
    const address = Crypto.address(seed, BLOCKCHAIN_CHAIN_ID);
    const path = `/addresses/balance?address=${address}`;
    return await this.request<FetchBalanceResponses>(path, "GET");
  }
}
