import React from "react";
import CSVReader, { IFileInfo } from "react-csv-reader";
import { useCsvContext } from "@contexts/CsvContext";
import "./ImportCSV.scss";

interface Props {
  disabled: boolean;
}
const papaparseOptions = {
  skipEmptyLines: true
};

const ImportCSV = ({ disabled }: Props) => {
  const { handleLoadRawCsv } = useCsvContext();
  const inputRef = React.useRef<any>(null);

  const resetFilesList = () => {
    if (!inputRef.current) return;

    inputRef.current.value = "";
  };

  return (
    <CSVReader
      inputId="csv"
      onFileLoaded={(data: string[][], fileInfo: IFileInfo, originalFile?: File) => {
        handleLoadRawCsv(data, fileInfo.type);
        resetFilesList();
      }}
      parserOptions={papaparseOptions}
      cssInputClass={`custom-file-input ${disabled ? "disabled" : ""}`}
      cssLabelClass="csv-label"
      label=""
      disabled={disabled}
      inputRef={(r) => (inputRef.current = r)}
    />
  );
};

export default ImportCSV;
