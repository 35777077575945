import React from "react";
import { Link } from "react-router-dom";
import Group from "@components/arrangement/Group/Group";
import Container from "@components/layoutElements/Container/Container";
import "./Navbar.scss";
import { useResetContext } from "@contexts/ResetContext";

const Navbar = () => {
  const { resetApp } = useResetContext();

  const appVersion = (globalThis as any).appVersion || "";

  return (
    <div className="navbar">
      <Container>
        <Link to={"/"} onClick={resetApp}>
          <Group colGap={10} className="group">
            <img src="/images/brand-logo.svg" alt="page logo" />
            <h4>Factory</h4>
          </Group>
        </Link>
      </Container>
      <p className="white">v{appVersion}</p>
    </div>
  );
};

export default Navbar;
