import React from "react";
import useLoading from "./useLoading";

interface FetchProps {
  request: () => Promise<FetchBalanceResponses>;
}

export interface FetchBalanceResponse {
  id: string;
  balance: number;
}

export type FetchBalanceResponses = FetchBalanceResponse[];

export interface UseFetchValue {
  startLoading: () => void;
  stopLoading: () => void;
  isLoading: boolean;
  response: FetchBalanceResponse | null;
  fetchError: string;
  doRequest: () => Promise<void>;
}

const useFetch = ({ request }: FetchProps): UseFetchValue => {
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [fetchError, setFetchError] = React.useState("");
  const [response, setResponse] = React.useState<FetchBalanceResponse | null>(null);

  const doRequest = async () => {
    startLoading();

    try {
      const response: FetchBalanceResponses = await request();
      const balanceData = response[0];
      setResponse(balanceData);
    } catch (e: unknown) {
      console.log("balance error ", e);
      if (e instanceof Error) setFetchError(e.message);
    } finally {
      stopLoading();
    }
  };

  return { isLoading, startLoading, stopLoading, response, fetchError, doRequest };
};

export default useFetch;
