import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { RightOutlined } from "@ant-design/icons";

import { availableRoutes } from "@data/availableRoutes";
import Stack from "@components/arrangement/Stack/Stack";

import "./TitlePage.scss";

const TitlePage = () => {
  const navigate = useNavigate();

  const goToSteps = () => {
    navigate(availableRoutes.steps);
  };

  return (
    <Stack className="title-page" alignItems="center" justifyContent="center">
      <h1 className="title primary">NFT Factory</h1>
      <p className="subheader mb-6">A dedicated tool for mass creating NFT tokens</p>
      <Button type="primary" size="large" onClick={goToSteps}>
        Create NFTs
        <RightOutlined />
      </Button>
    </Stack>
  );
};

export default TitlePage;
