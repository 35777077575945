import React from "react";

import { BlockchainNodeClient } from "@services/BlockchainNodeClient";
import { useSeedContext } from "@contexts/SeedContext";
import useFetch from "@hooks/useFetch";
import { useCsvContext } from "@contexts/CsvContext";
import { SINGLE_MINT_COST, SKEY_DECIMAL } from "@data/constants";

interface IContext {
  children: React.ReactNode;
}

const BalanceVerifierContext = React.createContext(null as any);

const DEBOUNCE_TIME = 2000;

interface BalanceVerifierValues {
  fetchSeedLoading: boolean;
  fetchError: string;
  verificationError: string;
  totalMintCost: number;
  fetchBalance: () => Promise<void>;
}

export const BalanceVerifierProvider = ({ children }: IContext) => {
  const { seed } = useSeedContext();
  const { csvRawData } = useCsvContext();
  const [verificationError, setVerificationError] = React.useState("");
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);

  const tokensToMintCount: number = csvRawData?.filter((row: string[]) => row.includes("mint")).length ?? 0;
  const totalMintCost = parseFloat((BigInt(tokensToMintCount) * BigInt(SINGLE_MINT_COST)).toString()) / SKEY_DECIMAL;

  const verifyBalance = (requiredBalance: number, givenBalance: number) => {
    if (requiredBalance > 0 && givenBalance < requiredBalance) {
      setVerificationError(`${givenBalance} Skey is not enough`);
    } else {
      setVerificationError("");
    }
  };

  const clearStateTimeout = () => {
    if (!timer) return;
    clearTimeout(timer);
    setTimer(null);
  };

  const debounce = (func: any, timeout = DEBOUNCE_TIME) => {
    if (timer) clearStateTimeout();

    setTimer(
      setTimeout(() => {
        func();
      }, timeout)
    );
  };

  const {
    startLoading,
    stopLoading,
    isLoading: isFetchBalanceLoading,
    response,
    fetchError,
    doRequest: fetchBalance
  } = useFetch({
    request: () => BlockchainNodeClient.fetchBalance(seed)
  });

  React.useEffect(() => {
    if (seed.trim() === "") {
      setVerificationError("");
      stopLoading();
      return;
    }

    startLoading();
    debounce(fetchBalance, DEBOUNCE_TIME);
  }, [seed]);

  React.useEffect(() => {
    //verify if balance is enough
    if (!response || seed.trim() === "") return;
    verifyBalance(totalMintCost, response.balance);
  }, [response, totalMintCost, seed]);

  return (
    <BalanceVerifierContext.Provider
      value={{ fetchSeedLoading: isFetchBalanceLoading, fetchError, verificationError, totalMintCost, fetchBalance }}
    >
      {children}
    </BalanceVerifierContext.Provider>
  );
};

export const useBalanceVerifier = (): BalanceVerifierValues => React.useContext(BalanceVerifierContext);
