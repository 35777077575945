import { Button, Spin } from "antd";
import { CheckOutlined, LoadingOutlined, WarningOutlined } from "@ant-design/icons";

import { TokenWithMeta, useMintProvider } from "@contexts/MintContext";

// import useMintToken from "@pages/CreateTokensSteps/StepsWrapper/SummaryStep/TokenItem/hook/useMintToken";
import Group from "@components/arrangement/Group/Group";

import "./TokenItem.scss";

const TokenItem = (tokenObj: TokenWithMeta) => {
  const { mintTokens } = useMintProvider();
  const { doneStatus, token } = tokenObj;

  const { image, croppedImage, ...rest } = token;
  const asArray = Object.values(rest);
  const antIcon = <LoadingOutlined spin twoToneColor={"#e7a525"} />;

  return (
    <Group className={`token-item-wrapper relative`}>
      {asArray.map((tokenValue, index) => (
        <p className={`cell ${doneStatus}`} title={tokenValue ? tokenValue : "-"} key={index}>
          {tokenValue ? tokenValue : "-"}
        </p>
      ))}
      <span className={`process-status ${doneStatus}`}>
        {(doneStatus === "pending" || doneStatus === "retry") && <Spin size="default" indicator={antIcon} />}
        {doneStatus === "done" && <CheckOutlined />}
        {doneStatus === "failed" && <WarningOutlined />}
      </span>
      {doneStatus === "failed" && (
        <Button className="retry-btn" size="middle" type="primary" onClick={() => mintTokens(tokenObj.id)}>
          Continue from here
        </Button>
      )}
    </Group>
  );
};

export default TokenItem;
