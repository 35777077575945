import type { IApiClient } from "./ApiClient";
import * as Transactions from "@waves/waves-transactions";
import { Schema } from "@contexts/SchemasContext";
import { TagType } from "./interfaces/ITokenData";
import { AxiosError, AxiosResponse } from "axios";
import { TokenDTO, UploadTokenBe } from "./interfaces/dtos";

export type Action = "draft" | "mint";

export type StaticValidation = {
  title: string;
  tag_id?: string;
  tag_type?: TagType;
  uuid?: string;
  set_id?: string;
  action: Action;
};

export type TokenData = {
  image: File;
  croppedImage: Promise<File>;
  fileId: string | null;
} & StaticValidation;

export type StaticValidationKeys = keyof StaticValidation;

export const staticTokenFields: StaticValidationKeys[] = ["title", "tag_id", "tag_type", "uuid", "set_id", "action"]; //image is provided at the other step and cannot be validated with rest of fields

export const belongsToStaticValidation = (key: string) => {
  return staticTokenFields.includes(key as any);
};

export interface IApiWrapper {
  createToken(token: TokenData, schemaId: string): Promise<TokenDTO>;
}

export class ApiWrapper implements IApiWrapper {
  private defaultSchemaId?: string;

  constructor(private client: IApiClient) {}

  async createToken(token: TokenData, schemaId: string): Promise<TokenDTO> {
    let draft;
    let id;

    try {
      draft = await this.createDraft(token, schemaId);
      id = draft.id;
    } catch (e) {
      if (e instanceof AxiosError) console.log(e);
      throw e;
    }

    if (token.action === "mint" && !!id) {
      try {
        await this.mintToken(id);
      } catch (e) {
        await this.removeDraft(id);
        console.log(e);
        throw e;
      }
    }

    return draft;
  }

  async getSchemas(): Promise<Schema[]> {
    const path = "/token_schemas";

    const response = await this.client.request({ method: "GET", url: path });

    return response.data;
  }

  async fetchBalance() {
    const path = "/account/balance";
    const response = await this.client.request({ method: "GET", url: path });

    return response;
  }

  private async createDraft(token: TokenData, schemaId: string): Promise<TokenDTO> {
    await this.assertDefaultSchemaId();

    const form = await this.createTokenDraftForm(token, schemaId);

    const res: AxiosResponse<TokenDTO> = await this.client.request({ method: "POST", url: "/tokens", data: form });
    console.log(res.data);
    return res.data;
  }

  private async mintToken(id: string) {
    const path = `/tokens/${id}/mint`;

    const { data } = await this.client.request({
      method: "GET",
      url: path,
      params: { networkId: this.client.getNetworkId() }
    });

    const { cid, timestamp, tx } = data;
    const signedTx = Transactions.signTx(tx, this.client.getSeed());

    await this.client.request({
      method: "POST",
      url: path,
      data: {
        networkId: this.client.getNetworkId(),
        proof: signedTx.proofs[0],
        timestamp,
        cid
      }
    });
  }

  private async removeDraft(id: string) {
    await this.client.request({ method: "DELETE", url: `/tokens/${id}` });
  }

  private async assertDefaultSchemaId() {
    if (this.defaultSchemaId) return;

    const res = await this.client.request({ method: "GET", url: "/token_schemas/default" });

    const id: string = res.data.id;
    if (!id) throw new Error("Default schema id missing");

    this.defaultSchemaId = id;
  }

  private async createTokenDraftForm(token: TokenData, schemaId: string): Promise<FormData> {
    const form = new FormData();

    form.append("title", token.title);
    form.append("schemaId", schemaId);
    form.append("files", token.image);

    const localCroppedImage = await token.croppedImage;
    form.append("files", localCroppedImage);

    if (!!token.fileId) {
      form.append("fileId", token.fileId);
    }

    if (!!token.tag_id) {
      form.append("tmpTagId", token.tag_id.toUpperCase());
    }

    if (!!token.tag_type) {
      form.append("tagType", token.tag_type.toLowerCase());
    }

    if (!!token.uuid) {
      form.append("id", token.uuid.toLowerCase());
    }

    if (!!token.set_id) {
      form.append("setId", token.set_id.toLowerCase());
    }

    const { title, image, croppedImage, action, tag_id, tag_type, uuid, set_id, fileId, ...rest } = token;

    const withoutEmpties = Object.fromEntries(Object.entries(rest).filter(([_, v]) => v !== ""));
    const jsonData = JSON.stringify(withoutEmpties);

    form.append("data", jsonData);

    return form;
  }
}
