import React from "react";
import { useSeedContext } from "@contexts/SeedContext";
import { useCsvContext } from "./CsvContext";
import { useImageConext } from "@contexts/ImageConext";
import { useMintProvider } from "./MintContext";
import { useSchemasContext } from "./SchemasContext";

interface IContext {
  children: React.ReactNode;
}

const ResetContext = React.createContext(null as any);

export const ResetProvider = ({ children }: IContext) => {
  const { resetSeed } = useSeedContext();
  const { resetCSVs } = useCsvContext();
  const { resetImage } = useImageConext();
  const { resetTokensWithFunctions, resetFileId } = useMintProvider();
  const { resetCurrentSchema } = useSchemasContext();

  const resetApp = () => {
    resetSeed();
    resetCSVs();
    resetImage();
    resetCurrentSchema();
    resetTokensWithFunctions();
    resetFileId();
  };

  return <ResetContext.Provider value={{ resetApp }}>{children}</ResetContext.Provider>;
};

export const useResetContext = () => React.useContext(ResetContext);
