import { Tooltip } from "antd";

import { useSeedContext } from "@contexts/SeedContext";
import { useBalanceVerifier } from "@contexts/BalanceVerifierContext";

import SeedInput from "./SeedInput/SeedInput";
import NavigatorButtons from "@pages/CreateTokensSteps/NavigatorButtons/NavigatorButtons";
import Hint from "@pages/CreateTokensSteps/Hint/Hint";

import "./EnterSeed.scss";

const Step1 = () => {
  const { seed } = useSeedContext();
  const { fetchSeedLoading, verificationError, totalMintCost } = useBalanceVerifier();

  const nextDisabled = seed.trim() === "" || fetchSeedLoading || verificationError !== "";

  return (
    <div className="step1">
      <h2 className="mb-2">Enter seed</h2>
      <p className="description mb-8" style={{ maxWidth: "390px" }}>
        <Tooltip title="Seed is a series of words generated by your cryptocurrency wallet." placement="left">
          <u>Seed</u>
        </Tooltip>
        <span style={{ marginLeft: "5px" }}>will be used to authorize creation of each NFT</span>
      </p>
      <SeedInput />
      <Hint>
        You need to have at least <span className="primary">{totalMintCost} Skey</span> on your wallet, according to
        tokens amount you want to mint
      </Hint>
      <NavigatorButtons
        nextDisabled={
          false
          // nextDisabled
        }
        prevDisabled={false}
        prevLabel="Back"
      />
    </div>
  );
};

export default Step1;
