import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import * as Crypto from "@waves/ts-lib-crypto";
import { Buffer } from "buffer";

export interface ApiClientOptions {
  baseUrl: string;
  seed: string;
}

export interface SignPayload {
  salt: string;
  address: string;
  publicKey: string;
  timestamp: number;
}

export interface Network {
  id: string;
  name: string;
  type: string;
  chainId: number;
  [key: string]: any;
}

export interface IApiClient {
  request(config: AxiosRequestConfig): Promise<AxiosResponse<any, any>>;
  getNetworkId(): string;
  getSeed(): string;
}

export class ApiClient implements IApiClient {
  private axios: AxiosInstance;
  private accessToken?: string;
  private refreshToken?: string;
  private seed: string;
  private networkId?: string;
  // private defaultNetwork: Network | null = null;

  constructor(private options: ApiClientOptions) {
    this.seed = options.seed.trim();
    this.axios = this.createAxiosInstance();
  }

  async request(config: AxiosRequestConfig) {
    if (!this.refreshToken) {
      await this.login();
    }

    try {
      return await this.axios.request(config);
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 401) {
        await this.refresh();
      }

      return await this.axios.request(config);
    }
  }

  getNetworkId() {
    if (!this.networkId) throw new Error("Network id is missing");

    return this.networkId;
  }

  getSeed() {
    return this.seed;
  }

  private async login() {
    // if (!this.defaultNetwork) {
    const network = await this.getDefaultNetwork();
    // }

    const { data } = await this.axios.post("/auth/login", {
      networkId: network.id,
      ...this.createSignPayload(network.chainId)
    });

    this.networkId = network.id;

    this.accessToken = data.accessToken;
    this.refreshToken = data.refreshToken;
  }

  private async refresh() {
    const { data } = await this.axios.post("/auth/refresh", { refreshToken: this.refreshToken });

    this.accessToken = data.accessToken;
    this.refreshToken = data.refreshToken;
  }

  private createSignPayload(chainId: number) {
    const address = Crypto.address(this.seed, chainId);
    const publicKey = Crypto.publicKey(this.seed);

    const data: SignPayload = {
      salt: "GO2NFT_WVS_LOGIN",
      address: address,
      publicKey,
      timestamp: Date.now()
    };

    const bytes = Buffer.from(JSON.stringify(data), "utf8");
    const signature = Crypto.signBytes(this.seed, bytes);

    return { data, signature };
  }

  private async getDefaultNetwork() {
    const res = await this.axios.get("/networks");
    const networks: Network[] = res.data;

    const main = networks.find((net) => net.type === "wvs" && net.name === "skey");
    if (!main) throw new Error("Default network not found");

    return main;
  }

  private createAxiosInstance() {
    const instance = axios.create({
      baseURL: this.options.baseUrl,
      headers: {
        "g2n-application": "g2n/android",
        "g2n-version": "1.0.0"
      }
    });

    instance.interceptors.request.use((config) => {
      if (!this.accessToken) return config;

      config.timeout = 60000;
      config.headers ??= {};
      config.headers["Authorization"] = `Bearer ${this.accessToken}`;

      return config;
    });

    return instance;
  }
}
