import useBoolean from "@hooks/useBoolean";
import { clearLocalStorageItems, readLocalStorage, writeLocalStorage } from "src/utils/localStorage";
import React from "react";

interface IContext {
  children: React.ReactNode;
}

const SeedContext = React.createContext(null as any);

const SEED_LOCAL_STORAGE_KEY = "factory_seed";

interface SeedContextValue {
  seed: string;
  handleSetSeed: (seed: string) => void;
  resetSeed: () => void;
  isSeedSaved: boolean;
  saveSeed: () => void;
  forgetSeed: () => void;
  toggleSaveSeed: () => void;
}

export const SeedProvider = ({ children }: IContext) => {
  const initialSeed = readLocalStorage<string>(SEED_LOCAL_STORAGE_KEY);
  const [seed, setSeed] = React.useState<string>(initialSeed ?? "");

  const [isSeedSaved, saveSeed, forgetSeed, toggleSaveSeed, reset] = useBoolean(Boolean(initialSeed));

  const resetSeed = () => {
    if (!isSeedSaved) setSeed("");
  };
  const handleSetSeed = (seed: string) => setSeed(seed);

  React.useEffect(() => {
    if (isSeedSaved) {
      writeLocalStorage(SEED_LOCAL_STORAGE_KEY, seed);
    } else {
      clearLocalStorageItems([SEED_LOCAL_STORAGE_KEY]);
    }
  }, [saveSeed, seed]);

  return (
    <SeedContext.Provider value={{ seed, handleSetSeed, resetSeed, isSeedSaved, saveSeed, forgetSeed, toggleSaveSeed }}>
      {children}
    </SeedContext.Provider>
  );
};

export const useSeedContext = (): SeedContextValue => React.useContext(SeedContext);
