import React from "react";

import { TokenWithMeta } from "@contexts/MintContext";

import { ApiWrapper } from "@services/ApiWrapper";
import { useSchemasContext } from "@contexts/SchemasContext";
import { useSeedContext } from "@contexts/SeedContext";
import { ApiClient } from "@services/ApiClient";
import { GO2NFT_API_URL } from "@config/config";
import { AxiosError } from "axios";
import useBoolean from "./useBoolean";
import { sleep } from "src/utils/sleep";

const useMintTokens = (
  tokens: TokenWithMeta[],
  trigger: boolean,
  onMintSuccess: (doneTokenId: number) => void,
  onMintFail: (failTokenId: number) => void,
  updateMintError: (errorMessage: string) => void,
  retryMint: (tokrenToRetryId: number) => void,
  updateFileId: (id: string) => void,
  globalFileId: string | null
) => {
  const { seed } = useSeedContext();
  const { currentSchema } = useSchemasContext();
  const apiWrapper = React.useMemo(() => new ApiWrapper(new ApiClient({ baseUrl: GO2NFT_API_URL, seed })), [seed]);

  const [isLoading, startLoading, stopLoading] = useBoolean(false);

  const allAreDone = tokens.every((token) => token.doneStatus === "done");
  const atLeastOneCreashed = tokens.some((token) => token.doneStatus === "failed");

  const createToken = async (tokenWithMeta: TokenWithMeta) => {
    startLoading();

    try {
      const token = await apiWrapper.createToken({ ...tokenWithMeta.token, fileId: globalFileId }, currentSchema!.id);
      // console.log(token);
      updateFileId(token.fileId);
      onMintSuccess(tokenWithMeta.id);
    } catch (e) {
      if (tokenWithMeta.doneStatus === "pending") {
        await sleep(3000);
        retryMint(tokenWithMeta.id);
      } else if (tokenWithMeta.doneStatus === "retry") {
        onMintFail(tokenWithMeta.id);
        console.log(e);
        if (e instanceof AxiosError) {
          return updateMintError(e.response?.data?.message || e.message);
        }
        if (e instanceof Error) {
          return updateMintError(e.message);
        }
      }
    } finally {
      stopLoading();
    }
  };

  React.useEffect(() => {
    if (!currentSchema) return;
    if (!seed) return;
    if (tokens?.length === 0) return;
    if (!trigger) return;
    if (isLoading) return;
    if (allAreDone || atLeastOneCreashed) return;

    const closestToRetry = tokens.findIndex((token) => token.doneStatus === "retry");
    const closestToStart = tokens.findIndex((token) => token.trigger);

    if (closestToRetry > -1) {
      const tokenToProcess = tokens[closestToRetry];
      createToken(tokenToProcess);
    } else if (closestToStart > -1) {
      const tokenToProcess = tokens[closestToStart];
      createToken(tokenToProcess);
    }
  }, [tokens, trigger, isLoading, currentSchema, seed]);
};

export default useMintTokens;
