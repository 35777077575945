import React from "react";

interface IContext {
  children: React.ReactNode;
}

const StepsStateMachineConext = React.createContext(null as any);

type Step = 0 | 1 | 2 | 3;

const getNextStep = (currentStep: Step) => {
  switch (currentStep) {
    case 0:
      return 1;
    case 1:
      return 2;
    case 2:
      return 3;
    case 3:
      return 3;
  }
};

const getPrevStep = (currentStep: Step) => {
  switch (currentStep) {
    case 0:
      return 0;
    case 1:
      return 0;
    case 2:
      return 1;
    case 3:
      return 2;
  }
};

interface StepsStateMachineValue {
  step: Step;
  onNextStep: () => void;
  onPrevStep: () => void;
}

export const StepsStateMachineProvider = ({ children }: IContext) => {
  const [step, setStep] = React.useState<0 | 1 | 2 | 3>(0);

  const onNext = () => {
    setStep(getNextStep(step));
  };

  const onPrev = () => {
    setStep(getPrevStep(step));
  };

  return (
    <StepsStateMachineConext.Provider value={{ step, onNextStep: onNext, onPrevStep: onPrev }}>
      {children}
    </StepsStateMachineConext.Provider>
  );
};

export const useStepsStateMachineConext = (): StepsStateMachineValue => React.useContext(StepsStateMachineConext);
