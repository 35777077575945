import CreateTokenSteps from "@pages/CreateTokensSteps/CreateTokenSteps";
import TitlePage from "@pages/TitlePage/TitlePage";
import { availableRoutes } from "../../data/availableRoutes";

interface IRoute {
  Component: () => JSX.Element;
  path: string;
}

export const routes: IRoute[] = [
  {
    Component: TitlePage,
    path: availableRoutes.titlePage
  },
  {
    Component: CreateTokenSteps,
    path: availableRoutes.steps
  }
];
