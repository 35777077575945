import React from "react";
import { FixedCropperRef } from "react-advanced-cropper";

import { VALID_MIME_TYPES } from "src/utils/csvValidation";

import { dataURLtoFile } from "src/utils/dataUrlToFile";

interface IContext {
  children: React.ReactNode;
}

const isValidType = (type: string) => {
  return VALID_MIME_TYPES.includes(type);
};

const isImageValid = (image: File) => {
  const isImageTypeValid = isValidType(image.type);
  const isImageSizeFit = image.size < 52428800;
  return isImageTypeValid && isImageSizeFit;
};

const ImageConext = React.createContext(null as any);

interface ImageContextValue {
  handleUploadImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  image: File | null;
  resetImage: () => void;
  initialImage: string;
  onChange: (cropper: FixedCropperRef) => void;
  cropperRef: React.RefObject<FixedCropperRef>;
  onCrop: () => void;
  croppedImage: Promise<File>;
}

export const ImageProvider = ({ children }: IContext) => {
  const cropperRef = React.useRef<FixedCropperRef>(null);
  const [image, setImage] = React.useState<File | null>(null);

  const imageBlob = !!image && new Blob([image], { type: "image/png" });
  const initialImage = !!imageBlob ? URL.createObjectURL(imageBlob) : "";

  const [cropped, setCropped] = React.useState("");

  const onChange = (cropper: FixedCropperRef) => {
    cropper.getCoordinates();
    cropper.getCanvas();
  };

  const onCrop = () => {
    const current = cropperRef?.current;
    if (!current) return;

    const croppedImage = current.getCanvas()?.toDataURL();
    setCropped(croppedImage || "");
  };

  const resetImage = () => setImage(null);

  const handleUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files || [];
    const image = files[0] || null;

    if (!files || files?.length === 0 || !image || !isImageValid(image)) return resetImage();

    setImage(image);
  };

  return (
    <ImageConext.Provider
      value={{
        handleUploadImage,
        image,
        resetImage,
        cropperRef,
        onChange,
        initialImage,
        onCrop,
        croppedImage: dataURLtoFile(cropped)
      }}
    >
      {children}
    </ImageConext.Provider>
  );
};

export const useImageConext = (): ImageContextValue => React.useContext(ImageConext);
