import React from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import Group from "@components/arrangement/Group/Group";
import { Button } from "antd";
import { useStepsStateMachineConext } from "../StepsStateMachineConext";
import "./NavigatorButtons.scss";

export interface INavigatorProps {
  nextDisabled: boolean;
  prevDisabled: boolean;
  prevLabel?: string;
  nextLabel?: string;
  customNextAction?: () => void;
  customPrevAction?: () => void;
}

const NavigatorButtons = ({
  nextDisabled,
  prevDisabled,
  prevLabel = "Back",
  nextLabel = "Next",
  customPrevAction,
  customNextAction
}: INavigatorProps) => {
  const { onNextStep, onPrevStep } = useStepsStateMachineConext();

  const onNextAction = nextDisabled ? () => {} : customNextAction || onNextStep;
  const onPrevAction = prevDisabled ? () => {} : customPrevAction || onPrevStep;

  return (
    <Group justifyContent="space-between" className=" navigator-buttons mt-8">
      <Button type="default" size="large" disabled={prevDisabled} onClick={onPrevAction}>
        <Group colGap={4}>
          <LeftOutlined />
          <span>{prevLabel}</span>
        </Group>
      </Button>

      <Button type="primary" size="large" disabled={nextDisabled} onClick={onNextAction}>
        <Group colGap={4}>
          <span>{nextLabel}</span>
          <RightOutlined />
        </Group>
      </Button>
    </Group>
  );
};

export default NavigatorButtons;
