import Group from "@components/arrangement/Group/Group";
import Motion from "@components/ux/Motion/Motion";
import { Progress } from "antd";
import React from "react";
import "./ProgressBar.scss";

interface ProgressBarProps {
  appear: boolean;
  percentDone: number;
  totalTokens: number;
  processedTokens: number;
}

const ProgressBar = ({ appear, percentDone, totalTokens, processedTokens }: ProgressBarProps) => {
  return (
    <>
      {appear && (
        <Motion duration={5} slide={{ active: appear, direction: "top", placement: "left" }} className="progress-bar">
          <Group justifyContent="space-between">
            <p className="mb-1 description">Progress:</p>
            <p className="mb-1 description">{`${processedTokens} / ${totalTokens}`}</p>
          </Group>
          <Progress type="line" percent={percentDone} showInfo={false} strokeWidth={8} strokeColor={"#e7a525"} />
        </Motion>
      )}
    </>
  );
};

export default ProgressBar;
