type AppStage = "dev" | "prod";

const APP_STAGE_ENV = process.env.REACT_APP_STAGE ?? null;
const REACT_APP_API_URL_ENV = process.env.REACT_APP_API_URL ?? null;
const REACT_APP_BLOCKCHAIN_NODE_URL_ENV = process.env.REACT_APP_BLOCKCHAIN_NODE_URL ?? null;
const REACT_APP_BLOCKCHAIN_CHAIN_ID_ENV = process.env.REACT_APP_BLOCKCHAIN_CHAIN_ID ?? null;

const getAppStage = (): AppStage => {
  if (!APP_STAGE_ENV) throw Error("Need to specify app stage");

  return APP_STAGE_ENV as AppStage;
};

const getGo2NftApiURL = (): string => {
  if (!REACT_APP_API_URL_ENV) {
    throw new Error("There is no REACT_APP_API_URL specified in env file");
  }
  return REACT_APP_API_URL_ENV;
};

const getBlockchainNodeUrl = (): string => {
  if (!REACT_APP_BLOCKCHAIN_NODE_URL_ENV) {
    throw new Error("There is no REACT_APP_BLOCKCHAIN_NODE_URL specified in env file");
  }
  return REACT_APP_BLOCKCHAIN_NODE_URL_ENV;
};

const getBlockchainChainId = (): string => {
  if (!REACT_APP_BLOCKCHAIN_CHAIN_ID_ENV)
    throw new Error("There is no REACT_APP_BLOCKCHAIN_CHAIN_ID specified in env file");

  return REACT_APP_BLOCKCHAIN_CHAIN_ID_ENV;
};

export const APP_STAGE = getAppStage();

export const GO2NFT_API_URL = getGo2NftApiURL();
export const BLOCKCHAIN_NODE_URL = getBlockchainNodeUrl();
export const BLOCKCHAIN_CHAIN_ID = getBlockchainChainId();
