import { Schema, TokenField, TokenFieldType } from "@contexts/SchemasContext";
import { belongsToStaticValidation, StaticValidation, staticTokenFields } from "@services/ApiWrapper";
import { staticValidationMap } from "./csvValidation";

const fieldsMap = new Map([]);

const mapGet = (index: number): any => {
  return fieldsMap.get(index);
};

const mapColumnNamesFromCsvToRequired = (requiredColumnNamesOrder: string[], providedColumnNamesOrder: string[]) => {
  requiredColumnNamesOrder.forEach((requiredColumnName) => {
    const index = providedColumnNamesOrder.indexOf(requiredColumnName);
    fieldsMap.set(index, requiredColumnName);
  });
};

export const validateCsv = (schema: Schema, currentCSV: string[][]) => {
  const totalErrors: string[] = [];

  if (!schema) throw new Error("Product category was not selected");

  const [csvHead, ...csvBody] = currentCSV;

  const headLength = csvHead.length;
  const headEmpty = headLength === 0;

  const bodyLength = csvBody.length;
  const bodyEmpty = bodyLength === 0;

  if (headEmpty) totalErrors.push(`Document has no columns`);
  if (bodyEmpty) totalErrors.push(`Document has no rows`);

  if (headEmpty || bodyEmpty) return { totalValid: false, totalErrors };

  const requiredSchemaFields: string[] = schema.fields.map((field: TokenField) => field.key);
  const requiredFields = [...staticTokenFields, ...requiredSchemaFields];

  fieldsMap.clear();
  mapColumnNamesFromCsvToRequired(requiredFields, csvHead);

  const { valid: columnsValid, errors: columnsErrors } = validateColumns(csvHead, requiredFields);
  totalErrors.push(...columnsErrors);

  const { valid: rowsValid, errors: rowsErrors } = validateBody(csvBody, schema);
  totalErrors.push(...rowsErrors);

  return { totalValid: columnsValid && rowsValid, totalErrors };
};

const validateColumns = (csvHead: string[], requiredFields: string[]) => {
  const errors: string[] = [];

  const valid = requiredFields.length === fieldsMap.size;

  if (!valid) {
    errors.push(
      `Uploaded file doesn't contain required columns, or column names are incorrect (order of columns doesn't matter, but case does), - SHOULD BE: ${requiredFields.join(
        ", "
      )}, GOT: ${csvHead.join(", ")}`
    );
  }

  return { valid, errors: [...errors] };
};

const validateBody = (csvBody: any[][], schema: Schema) => {
  const errors: string[] = [];
  let valid = true;

  csvBody.forEach((row, index) => {
    const { valid: rowValid, errors: rowErrors } = validateRow(row, index, schema);
    valid = rowValid;
    errors.push(...rowErrors);
  });

  return { valid, errors: [...errors] };
};

const validateRow = (row: (string | keyof StaticValidation)[], rowIndex: number, schema: Schema) => {
  let valid = true;
  const errors: string[] = [];

  row.forEach((field, fieldIndex) => {
    if (belongsToStaticValidation(mapGet(fieldIndex))) {
      const { valid: staticValid, errors: staticErrors } = staticValidationMap[
        mapGet(fieldIndex) as keyof StaticValidation
      ](rowIndex, field);

      valid = staticValid;
      errors.push(...staticErrors);
    } else {
      const fieldName = mapGet(fieldIndex);

      const schemaForField = schema?.fields.find((field) => field.key === fieldName);
      if (schemaForField) {
        const { valid: dynamicFieldValid, errors: dynamicFieldErrors } = validateDynamicFields(
          schemaForField,
          fieldName,
          rowIndex + 1,
          field
        );

        valid = dynamicFieldValid;
        errors.push(...dynamicFieldErrors);
      }
    }
  });

  return { valid, errors: [...errors] };
};

export const validateDynamicFields = (
  tokenFieldSchema: TokenField,
  fieldName: string,
  rowIndex: number,
  fieldValue?: string
) => {
  let valid = true;
  const errors: string[] = [];

  if (tokenFieldSchema.required && !fieldValue) {
    valid = false;
    errors.push(`[Row ${rowIndex}] ${fieldName} not provided, but required`);
  }

  if (fieldValue) {
    const minLength = tokenFieldSchema.data.minLength;
    const maxLength = tokenFieldSchema.data.maxLength;
    const regExp = tokenFieldSchema.data.regexp;
    const type = tokenFieldSchema.type;

    const fieldLength = fieldValue.length;

    typeValidation(fieldValue, type);

    if (type === "select") {
      const fieldMatchSelectOptions =
        tokenFieldSchema.data.selectOptions?.filter((option) => option.key === fieldValue).length !== 0;

      let options = "";
      tokenFieldSchema.data.selectOptions?.forEach((option) => (options += `${option}, `));

      if (!fieldMatchSelectOptions) {
        valid = false;
        errors.push(`[Row ${rowIndex}] ${fieldName} has valid options of ${options}`);
      }
    }

    if (minLength !== undefined) {
      if (!isAboveMinLength(fieldLength, minLength)) {
        valid = false;
        errors.push(`[Row ${rowIndex}] ${fieldName} is too short. Should be min length of ${minLength}`);
      }
    }
    if (maxLength !== undefined) {
      if (!isBelowMaxLenght(fieldLength, maxLength)) {
        valid = false;
        errors.push(`[Row ${rowIndex}] ${fieldName} is too long. Should be max length of ${maxLength}`);
      }
    }
    if (regExp !== undefined) {
      if (!matchRegexp(fieldValue, regExp)) {
        valid = false;
        errors.push(`[Row ${rowIndex}] ${fieldName} is not valid`);
      }
    }
  }

  return { valid, errors: [...errors] };
};

const matchRegexp = (fieldValue: string, regExpInput: string) => {
  const regExpInstance = new RegExp(regExpInput);
  return regExpInstance.test(fieldValue);
};

const isAboveMinLength = (fieldValue: number, minWidth: number) => {
  return fieldValue >= minWidth;
};

const isBelowMaxLenght = (fieldValue: number, maxWidth: number) => {
  return fieldValue <= maxWidth;
};

const typeValidation = (fieldValue: string, fieldType: TokenFieldType) => {
  try {
    if (fieldType === "boolean") return Boolean(fieldValue);
    if (fieldType === "date") return new Date(fieldValue);
    if (fieldType === "number") return Number(fieldValue);
    if (fieldType === "string") return fieldValue.toString();
  } catch (e) {
    alert(e);
  }
};
