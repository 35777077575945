import { GO2NFT_API_URL } from "@config/config";
import { ApiClient } from "@services/ApiClient";
import { ApiWrapper } from "@services/ApiWrapper";
import React from "react";
import { useSeedContext } from "@contexts/SeedContext";
import useLoading from "@hooks/useLoading";

interface IContext {
  children: React.ReactNode;
}

export type TokenFieldType = "string" | "number" | "boolean" | "date" | "select";

export interface SelectOption {
  key: string;
  label?: string; // set dynamically
}

export enum StringPresentationType {
  TEXT = "text",
  TEXTAREA = "textarea",
  URL = "url",
  DATE = "date",
  BOOLEAN = "boolean"
}

export interface ValidationFields {
  regexp?: string; //validation
  minLength?: number; //validation
  maxLength?: number; //validation
}

export type TokenFieldData = {
  selectOptions?: SelectOption[];
  dateFormat?: string;
  textPresentation?: StringPresentationType;
  placeholderKey?: string;
  placeholder?: string;
} & ValidationFields;

export interface TokenField {
  type: TokenFieldType;
  key: string;
  required: boolean;
  label?: string;
  data: TokenFieldData;
}

export type Schema = {
  id: string;
  name: string;
  fields: TokenField[];
  createdAt?: number;
  updatedAt?: number;
};

export interface SchemasContextValue {
  getSchemas: () => Promise<void>;
  schemas: Schema[] | null;
  isSchemasLoading: boolean;
  handleSetCurrentSchema: (schemaName: string) => void;
  currentSchema: Schema | null;
  resetCurrentSchema: () => void;
}

const SchemasContext = React.createContext(null as any);

export const SchemasProvider = ({ children }: IContext) => {
  const { seed } = useSeedContext();

  const [schemas, setSchemas] = React.useState<Schema[] | null>(null);
  const [currentSchema, setCurrentSchema] = React.useState<Schema | null>(null);

  const resetCurrentSchema = () => setCurrentSchema(null);

  const handleSetCurrentSchema = (schemaName: string) => {
    if (!schemas) return;

    const currentSchema = schemas.filter((schema) => schema?.name === schemaName)[0];
    setCurrentSchema(currentSchema);
  };

  const {
    isLoading: isSchemasLoading,
    startLoading: startLoadingSchemas,
    stopLoading: stopLoadingSchemas
  } = useLoading();

  const apiWrapper = new ApiWrapper(
    new ApiClient({
      baseUrl: GO2NFT_API_URL,
      seed
    })
  );

  const getSchemas = async () => {
    startLoadingSchemas();

    apiWrapper
      .getSchemas()
      .then((schemas) => {
        setSchemas(schemas);
        console.log(schemas);
        if (schemas.length === 1) setCurrentSchema(schemas[0]);
      })
      .catch((e) => console.log(e))
      .finally(stopLoadingSchemas);
  };

  return (
    <SchemasContext.Provider
      value={{
        getSchemas,
        schemas,
        isSchemasLoading,
        handleSetCurrentSchema,
        currentSchema,
        resetCurrentSchema
      }}
    >
      {children}
    </SchemasContext.Provider>
  );
};

export const useSchemasContext = (): SchemasContextValue => React.useContext(SchemasContext);
