import { Table, Tooltip } from "antd";
import { useCsvContext } from "@contexts/CsvContext";

import "./DataTable.scss";

export type StatusState = "ready" | "pending" | "done" | "error";

const prepareColumns = (data: string[][] | null) => {
  if (!data || data.length === 0) return [];
  const COLUMN_NAMES_INDEX = 0;

  //get column titles
  const filtered = data.find((_, i) => i === COLUMN_NAMES_INDEX) ?? [];

  return filtered?.map((key) => ({
    title: key,
    dataIndex: key,
    key,
    ellipsis: {
      showTitle: true
    }
  }));
};

interface ColumnNames {
  title: string;
  dataIndex: string;
  key: string;
}

const prepareRows = (tableRows: string[][], columnNames: ColumnNames[]) => {
  const COLUMN_NAMES_ROW_INDEX = 0;

  const tRows = tableRows.filter((k, i) => i !== COLUMN_NAMES_ROW_INDEX);

  const dataSource: Record<string, string>[] = [];

  tRows.forEach((table, index) => {
    const recordObject = {};
    table.forEach((record: string, i: number) =>
      Object.defineProperty(recordObject, columnNames[i].key, { value: record || "-" })
    );
    Object.defineProperty(recordObject, "key", { value: index });
    dataSource.push(recordObject);
  });

  return dataSource;
};

const DataTable = () => {
  const { csvForTable } = useCsvContext();

  const columnNames = prepareColumns(csvForTable);
  const dataSource = prepareRows(csvForTable || [[]], columnNames);

  return (
    <div className="data-table">
      {(csvForTable || []).length > 0 && (
        <>
          <h4 className="mb-2">Tokens preview</h4>
          <Table dataSource={dataSource} columns={columnNames} pagination={{ showSizeChanger: true, pageSize: 50 }} />
        </>
      )}
    </div>
  );
};

export default DataTable;
