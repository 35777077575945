import React from "react";
import { useSeedContext } from "@contexts/SeedContext";
import { Input, Spin } from "antd";
import { EyeInvisibleOutlined, EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import { useBalanceVerifier } from "@contexts/BalanceVerifierContext";
import Stack from "@components/arrangement/Stack/Stack";
import Group from "@components/arrangement/Group/Group";
import "./SeedInput.scss";
import CheckBox from "@components/dataEntry/Checkbox/CheckBox";

const SeedInput = () => {
  const { handleSetSeed, seed, toggleSaveSeed, isSeedSaved } = useSeedContext();
  const { fetchSeedLoading, verificationError } = useBalanceVerifier();

  const inputStatusStyle = (): React.CSSProperties["color"] | undefined => {
    if (verificationError) return "#f03535";
    if (!fetchSeedLoading && !!seed && !verificationError) return "#07a434";
    return undefined;
  };

  return (
    <Stack className="input-wrapper mb-4" rowGap={4}>
      <Group colGap={4} className="max">
        <Input.Password
          id="seed"
          value={seed}
          size="large"
          onChange={(e) => handleSetSeed(e.currentTarget.value)}
          // status={verificationError && "error"}
          placeholder="Seed consists of 12-24 words"
          iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
          style={{ borderColor: inputStatusStyle() }}
        />
        {fetchSeedLoading && <Spin indicator={<LoadingOutlined />} className="loader" />}
      </Group>
      {verificationError && (
        <p className="error" style={{ marginLeft: "20px" }}>
          {verificationError}
        </p>
      )}
      <Group colGap={8} alignItems="center" className="mt-1">
        <CheckBox checked={isSeedSaved} onChange={toggleSaveSeed} variant={"default"} />
        <p className="description s1">Save seed</p>
      </Group>
    </Stack>
  );
};

export default SeedInput;
