import { Router } from "@features/router/router";

export const appVersion = require("../package.json");
(globalThis as any).appVersion = appVersion.version || "";

const App = () => {
  return <Router />;
};

export default App;
