import React from "react";
import Stack from "@components/arrangement/Stack/Stack";
import { useCsvContext } from "@contexts/CsvContext";
import { Alert } from "antd";

const CSVErrors = () => {
  const { csvErrors } = useCsvContext();
  return (
    <>
      {csvErrors.length !== 0 && (
        <Stack rowGap={8} className="warnings-wrapper">
          {csvErrors.map((error: string, errorIndex: number) => {
            return <Alert message={error} type="warning" key={errorIndex} />;
          })}
        </Stack>
      )}
    </>
  );
};

export default CSVErrors;
