import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import Group from "@components/arrangement/Group/Group";
import { Schema, useSchemasContext } from "@contexts/SchemasContext";
import { Select, Spin } from "antd";
import { useCsvContext } from "@contexts/CsvContext";
import "./SchemasSelect.scss";

const SchemasSelect = () => {
  const { schemas, isSchemasLoading, handleSetCurrentSchema, currentSchema } = useSchemasContext();

  const { resetCSVs } = useCsvContext();

  const currentSchemaWithAdditionalAction = (value: string) => {
    handleSetCurrentSchema(value);
    resetCSVs();
  };

  const options = schemas ? schemas.map((schema) => ({ value: schema?.name, label: schema?.name })) : undefined;

  return (
    <Group colGap={8}>
      <Select
        onChange={(value) => currentSchemaWithAdditionalAction(value)}
        defaultValue={"Select product category"}
        value={currentSchema?.name}
        options={options}
        disabled={isSchemasLoading}
        style={{ width: "240px" }}
        loading={isSchemasLoading}
      />
      {/* {isSchemasLoading && <Spin indicator={<LoadingOutlined />} />} */}
    </Group>
  );
};

export default SchemasSelect;
