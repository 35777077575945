export const readLocalStorage = <T>(key: string): T | null => {
  const data = window.localStorage.getItem(key);
  return JSON.parse(data || "null");
};

export const writeLocalStorage = (key: string, value: any) => {
  const item = JSON.stringify(value);
  window.localStorage.setItem(key, item);
};

export const clearLocalStorageItems = (keys: string[]) => {
  keys.forEach((key) => window.localStorage.removeItem(key));
};
