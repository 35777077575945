import React from "react";
import { Steps } from "antd";
import EnterSeed from "./EnterSeedStep/EnterSeedStep";
import UploadImageStep from "./UploadImageStep/UploadImageStep";
import SummaryStep from "./SummaryStep/SummaryStep";
import UploadTokenDetailsStep from "./UploadTokensDetailStep/UploadTokensDetailStep";
import { useStepsStateMachineConext } from "../StepsStateMachineConext";
import "./StepsWrapper.scss";

const steps = [
  {
    title: "Upload tokens detail",
    content: <UploadTokenDetailsStep />
  },
  {
    title: "Enter seed",
    content: <EnterSeed />
  },
  {
    title: "Upload image",
    content: <UploadImageStep />
  },
  {
    title: "Create tokens",
    content: <SummaryStep />
  }
];

const items = steps.map((item) => ({ key: item.title, title: item.title }));

const StepsWrapper = () => {
  const { step } = useStepsStateMachineConext();

  return (
    <div className="steps">
      <p className="hint mb-6">Please follow this few simple steps</p>
      <Steps current={step} items={items} className="mb-10" />
      {steps[step].content}
    </div>
  );
};

export default StepsWrapper;
