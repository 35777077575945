import React from "react";
import { useNavigate } from "react-router-dom";
import { availableRoutes } from "@data/availableRoutes";
import { useResetContext } from "./ResetContext";

interface IContext {
  children: React.ReactNode;
}

const ModalStateContext = React.createContext(null as any);

export const ModalStateProvider = ({ children }: IContext) => {
  const [progressModalOpen, setProgressModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const { resetApp } = useResetContext();

  const openProgressModal = () => setProgressModalOpen(true);
  const closeProgressModal = () => {
    setProgressModalOpen(false);
    navigate(availableRoutes.titlePage);
    resetApp();
  };

  return (
    <ModalStateContext.Provider value={{ progressModalOpen, openProgressModal, closeProgressModal }}>
      {children}
    </ModalStateContext.Provider>
  );
};

export const useModalContext = () => React.useContext(ModalStateContext);
