import React from "react";

import { useCsvContext } from "@contexts/CsvContext";
import { useImageConext } from "@contexts/ImageConext";
import { useSchemasContext } from "@contexts/SchemasContext";

import useMintTokens from "@hooks/useMintTokens";

import { TokenData } from "@services/ApiWrapper";

interface IContext {
  children: React.ReactNode;
}

export type TokenProcessStatus = "done" | "failed" | "retry" | "pending" | "";

export type TokenMeta = {
  trigger: boolean;
  doneStatus: TokenProcessStatus;
  id: number;
};

export type TokenWithMeta = {
  token: TokenData;
} & TokenMeta;

export type WorkStatus = "prepared" | "processing" | "done" | "failed";

interface MintContextValue {
  mintTokens: (startingId: number) => void;
  tokensWithMeta: TokenWithMeta[];
  resetTokensWithFunctions: () => void;
  resetFileId: () => void;
  totalTokens: number;
  processedTokens: number;
  doneTokens: number;
  failedTokens: number;
  workStatus: WorkStatus;
  errorMessage: string;
  leftTokens: string[][];
}

const MintContext = React.createContext(null as any);

export const MintProvider = ({ children }: IContext) => {
  const { csvBody, csvHead, preparedCsvBody } = useCsvContext();
  const { image } = useImageConext();
  const { currentSchema } = useSchemasContext();

  const [globalFileId, setGlobalFileId] = React.useState<string | null>(null);

  const updateFileId = (id: string) => setGlobalFileId(id);
  const resetFileId = () => setGlobalFileId(null);

  const [tokensWithMeta, setTokensWithMeta] = React.useState<TokenWithMeta[]>([]);
  const [errorMessage, setErrorMessage] = React.useState("");

  const totalTokens = tokensWithMeta.length;
  const processedTokens = tokensWithMeta.filter(
    (token) => token.doneStatus === "done" || token.doneStatus === "failed"
  ).length;
  const doneTokens = tokensWithMeta.filter((token) => token.doneStatus === "done").length;
  const failedTokens = processedTokens - doneTokens;

  const getLeftTokens = (): string[][] => {
    const indexOfFirstFailed = tokensWithMeta.findIndex((token) => token.doneStatus === "failed");
    const tokensLeft = [csvHead, ...csvBody.filter((_, index) => index >= indexOfFirstFailed)];
    return tokensLeft;
  };

  const resetTokensWithFunctions = () => setTokensWithMeta([]);

  const mintTokens = (startingId: number) => {
    setTokensWithMeta((prev) => {
      return prev.map((tokenObj, index) => {
        const getDoneStatus = () => {
          if (index < startingId) return "done";
          if (index === startingId) return "pending";
          return "";
        };
        return {
          ...tokenObj,
          token: { ...tokenObj.token },
          trigger: index === startingId,
          doneStatus: getDoneStatus()
        };
      });
    });
  };

  const onMintSuccess = (doneTokenId: number) => {
    const nextTokenId = doneTokenId + 1;

    setTokensWithMeta((prev) => {
      return prev.map((tokenObj, index) => {
        const isDoneToken = index === doneTokenId;
        const isNextToken = index === nextTokenId;

        if (isDoneToken) {
          return {
            ...tokenObj,
            token: { ...tokenObj.token },
            doneStatus: "done",
            trigger: false
          };
        } else if (isNextToken) {
          return {
            ...tokenObj,
            token: { ...tokenObj.token },
            trigger: true,
            doneStatus: "pending"
          };
        } else {
          return tokenObj;
        }
      });
    });
  };

  const retryMint = (idOfTokenToRetry: number) => {
    setTokensWithMeta((prev) => {
      return prev.map((tokenObj, index) => {
        const isTokenToRetry = index === idOfTokenToRetry;

        if (isTokenToRetry) {
          return {
            ...tokenObj,
            token: { ...tokenObj.token },
            doneStatus: "retry"
          };
        }

        return tokenObj;
      });
    });
  };

  const onMintFail = (idOfFailedToken: number) => {
    setTokensWithMeta((prev) => {
      return prev.map((tokenObj, index) => {
        const isFailedToken = index === idOfFailedToken;

        if (isFailedToken) {
          return {
            ...tokenObj,
            token: { ...tokenObj.token },
            doneStatus: "failed",
            trigger: false
          };
        }

        return tokenObj;
      });
    });
  };

  const updateMintError = (error: string) => setErrorMessage(error);

  //every time new CSV comes in, load it to local state and append additional data to every item
  React.useEffect(() => {
    if (preparedCsvBody.length === 0 || !image || !currentSchema?.id) return;

    setTokensWithMeta(
      preparedCsvBody.map((token: TokenData, index: number) => {
        return {
          token,
          id: index,
          trigger: false,
          doneStatus: ""
        };
      })
    );
  }, [preparedCsvBody, image]);

  const tokensArePresent = totalTokens > 0;
  const isAnyTokenCrashed = tokensWithMeta.some((token) => token.doneStatus === "failed");
  const isAnyTokenTriggered = tokensWithMeta.some((token) => token.trigger);

  const isPrepared = tokensArePresent && processedTokens === 0 && !isAnyTokenTriggered;
  const doStart = tokensArePresent && isAnyTokenTriggered;
  const isProcessing = !isAnyTokenCrashed && tokensArePresent && processedTokens !== totalTokens;
  const isWorkDone = !isAnyTokenCrashed && tokensArePresent && processedTokens === totalTokens;

  const getWorkStatus = (): WorkStatus => {
    if (isAnyTokenCrashed) return "failed";
    if (isPrepared) return "prepared";
    if (doStart) return "processing";
    if (isProcessing) return "processing";
    if (isWorkDone) return "done";

    return "prepared";
  };

  useMintTokens(
    tokensWithMeta,
    isAnyTokenTriggered,
    onMintSuccess,
    onMintFail,
    updateMintError,
    retryMint,
    updateFileId,
    globalFileId
  );

  const leftTokens = getLeftTokens();

  return (
    <MintContext.Provider
      value={{
        mintTokens,
        tokensWithMeta,
        resetTokensWithFunctions,
        resetFileId,
        totalTokens,
        processedTokens,
        doneTokens,
        failedTokens,
        errorMessage,
        workStatus: getWorkStatus(),
        leftTokens
      }}
    >
      {children}
    </MintContext.Provider>
  );
};

export const useMintProvider = (): MintContextValue => React.useContext(MintContext);
