import { useImageConext } from "@contexts/ImageConext";

import { VALID_MIME_TYPES } from "src/utils/csvValidation";

import Stack from "@components/arrangement/Stack/Stack";

import "./ImportImage.scss";

const ImportImage = () => {
  const { handleUploadImage } = useImageConext();

  return (
    <Stack rowGap={48}>
      <input
        type="file"
        name="image"
        className="upload-image-input"
        accept={VALID_MIME_TYPES.join(",")}
        onChange={(event) => handleUploadImage(event)}
      />
    </Stack>
  );
};

export default ImportImage;
