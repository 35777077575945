import { FixedCropper } from "react-advanced-cropper";

import { useImageConext } from "@contexts/ImageConext";
import ImportImage from "./ImportImage/ImportImage";
import NavigatorButtons from "@pages/CreateTokensSteps/NavigatorButtons/NavigatorButtons";

import "./UploadImage.scss";
import "react-advanced-cropper/dist/style.css";
import { useStepsStateMachineConext } from "@pages/CreateTokensSteps/StepsStateMachineConext";

const Step3 = () => {
  const { initialImage, onChange, cropperRef, onCrop } = useImageConext();
  const { onNextStep } = useStepsStateMachineConext();
  const prevActionDisabled = false;

  return (
    <div className="step3">
      <h2 className="mb-2">Upload image</h2>
      <p className="description mb-5" style={{ maxWidth: "420px" }}>
        Image should represent a single product from the entire collection. It will be attached to every token you
        specified in the "Upload tokens detail" step.
      </p>
      <ImportImage />
      <FixedCropper
        src={initialImage}
        onChange={onChange}
        className="cropper"
        stencilSize={{ height: 800, width: 800 }}
        stencilProps={{ grid: true }}
        ref={cropperRef}
      />
      <NavigatorButtons
        nextDisabled={
          false
          // nextActionDisabled
        }
        customNextAction={() => {
          onCrop();
          onNextStep();
        }}
        prevDisabled={prevActionDisabled}
      />
    </div>
  );
};

export default Step3;
