import Container from "@components/layoutElements/Container/Container";
import Layout from "@components/layoutElements/Layout/Layout";
import Navbar from "@components/layoutElements/Navbar/Navbar";
import { ModalStateProvider } from "@contexts/ModalStateContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { routes } from "./routes";

export const Router = () => {
  return (
    <BrowserRouter basename="/">
      <ModalStateProvider>
        <Routes>
          {routes.map(({ path, Component }) => (
            <Route
              path={path}
              key={path}
              element={
                <Layout variant={"navbar-content-footer"} Navbar={Navbar}>
                  <Container>
                    <Component />
                  </Container>
                </Layout>
              }
            />
          ))}
        </Routes>
      </ModalStateProvider>
    </BrowserRouter>
  );
};
