// import { validateCsv } from "@services/utils/csvValidation";
import React from "react";
import { useImageConext } from "@contexts/ImageConext";
import { validateCsv } from "src/utils/dynamicCsvValidation";
import { useSchemasContext } from "./SchemasContext";
import { StaticValidationKeys, TokenData } from "@services/ApiWrapper";

export type StatusState = "ready" | "pending" | "done" | "error";

interface IContext {
  children: React.ReactNode;
}

const CsvContext = React.createContext(null as any);

type TableOfTables<T> = T[][];

interface CsvContextValue {
  handleLoadRawCsv: (csvData: string[][], dataType: string) => void;
  csvRawData: TableOfTables<string> | null;
  csvErrors: string[];
  resetErrors: () => void;
  resetCsvRawData: () => void;
  resetCSVs: () => void;
  preparedCsvBody: TokenData[];
  csvHead: string[];
  csvBody: string[][];
  csvForTable: TableOfTables<string> | null;
}

export const CsvProvider = ({ children }: IContext) => {
  const { image, croppedImage } = useImageConext();
  const { currentSchema } = useSchemasContext();

  const [csvRawData, setCsvRawData] = React.useState<string[][] | null>(null);

  const [csvErrors, setCsvErrors] = React.useState<string[]>([]);
  const [csvForTable, setCsvForTable] = React.useState<string[][] | null>(null);

  const setErrors = (errors: string[]) => setCsvErrors([...errors]);
  const resetErrors = () => setCsvErrors([]);

  const resetCsvRawData = () => setCsvRawData(null);
  const resetCsvToTable = () => setCsvForTable(null);

  const resetCSVs = () => {
    resetCsvRawData();
    resetCsvToTable();
  };

  const loadRawCsv = (data: string[][] | null) => {
    setCsvRawData(data);
  };

  const loadToDataToTable = (data: string[][] | null) => {
    setCsvForTable(data);
  };

  const handleWrongFileType = () => {
    alert("Wrong file type. CSV is required");
    resetCsvRawData();
  };

  const handleFailedValidation = (totalErrors: string[]) => {
    setErrors(totalErrors);
    resetCsvRawData();
  };

  const handleSuccesfullValidation = (csvData: string[][]) => {
    resetErrors();
    loadRawCsv(csvData);
    loadToDataToTable(csvData);
  };

  const handleLoadRawCsv = (csvData: string[][], dataType: string) => {
    if (!currentSchema) return;

    try {
      if (dataType !== "text/csv") return handleWrongFileType();

      const trimmedCsvData = csvData.map((fields) => fields.map((field) => field.trim()));
      const { totalValid, totalErrors } = validateCsv(currentSchema, trimmedCsvData);

      if (!totalValid || totalErrors.length > 0) return handleFailedValidation(totalErrors);

      handleSuccesfullValidation(trimmedCsvData);
    } catch (e) {
      console.log(e);
    }
  };

  const csvHead = !!csvRawData ? csvRawData[0] : [];
  const csvBody = !!csvRawData ? csvRawData.filter((_, rowIndex) => rowIndex !== 0) : [];

  const preparedCsvBody = csvBody.map((row: string[]) => {
    let dataObj: Record<string, string> = {};

    row.forEach((cell, cellIndex) => {
      dataObj[csvHead[cellIndex]] = cell;
    });

    const rowObj = {
      ...dataObj,
      image,
      croppedImage
    };

    dataObj = {};

    return rowObj as TokenData;
  });

  return (
    <CsvContext.Provider
      value={{
        handleLoadRawCsv,
        csvRawData,
        csvErrors,
        resetErrors,
        resetCsvRawData,
        resetCSVs,
        preparedCsvBody,
        csvHead,
        csvBody,
        csvForTable
      }}
    >
      {children}
    </CsvContext.Provider>
  );
};

export const useCsvContext = (): CsvContextValue => React.useContext(CsvContext);
