import { useMintProvider } from "@contexts/MintContext";
import { useCsvContext } from "@contexts/CsvContext";

import TokenItem from "../TokenItem/TokenItem";
import Group from "@components/arrangement/Group/Group";

import "./TokensList.scss";

const TokensList = () => {
  const { tokensWithMeta, workStatus } = useMintProvider();
  const { csvHead } = useCsvContext();

  return (
    <div className={`tokens-list ${workStatus}`}>
      <h4 className="mb-1">Preview</h4>
      <Group className={`wrapper`}>
        {csvHead?.map((headCell: string, headCellIndex: number) => (
          <p className={`title`} key={headCellIndex}>
            {headCell}
          </p>
        ))}
        <p className={`title`}>Process Status</p>
      </Group>
      <div className="all-rows">
        {tokensWithMeta?.map((token, index) => (
          <TokenItem {...token} key={index} />
        ))}
      </div>
    </div>
  );
};

export default TokensList;
