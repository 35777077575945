import { Alert, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { useMintProvider } from "@contexts/MintContext";
import { useResetContext } from "@contexts/ResetContext";

import { availableRoutes } from "@data/availableRoutes";

import { useStepsStateMachineConext } from "@pages/CreateTokensSteps/StepsStateMachineConext";
import Motion from "@components/ux/Motion/Motion";
import Portal from "@components/overlay/Portal/Portal";
import Group from "@components/arrangement/Group/Group";
import TokensList from "./TokensList/TokensList";
import ProgressBar from "./ProgressBar/ProgressBar";
import { CSVLink } from "react-csv";

import "./SummaryStep.scss";

const SummaryStep = () => {
  const navigate = useNavigate();

  const { mintTokens, processedTokens, totalTokens, workStatus, errorMessage, leftTokens } = useMintProvider();
  const { resetApp } = useResetContext();
  const { onPrevStep } = useStepsStateMachineConext();

  const handleCreateTokens = () => mintTokens(0);

  const goHome = () => {
    navigate(availableRoutes.titlePage);
    resetApp();
  };

  const percentDone = parseFloat(((processedTokens / totalTokens) * 100).toFixed(1));
  const endProcess = workStatus === "done";

  return (
    <div className="summary-step">
      <h2 className="mb-1">Create tokens</h2>
      <p className="description mb-6">Confirm to create tokens</p>
      <Group className="mb-10" justifyContent="flex-start" colGap={16}>
        {endProcess && (
          <Motion duration={5} pulse={true}>
            <Button type="primary" size="large" onClick={goHome} disabled={false}>
              End process
            </Button>
          </Motion>
        )}
        {!endProcess && (
          <>
            <Button type="default" size="large" onClick={onPrevStep} disabled={workStatus !== "prepared"}>
              <LeftOutlined />
              Go back
            </Button>
            <Motion duration={5} pulse={workStatus === "prepared"}>
              <Button type="primary" size="large" onClick={handleCreateTokens} disabled={workStatus !== "prepared"}>
                Create tokens {`[${totalTokens}]`}
              </Button>
            </Motion>
          </>
        )}
      </Group>
      <TokensList />
      {endProcess && (
        <Motion duration={5} pulse={true} className="mt-10">
          <Button type="primary" size="large" onClick={goHome} disabled={false}>
            End process
          </Button>
        </Motion>
      )}
      {workStatus === "failed" && (
        <Portal>
          <Motion
            duration={2}
            slide={{ direction: "left", active: true, placement: "bottom" }}
            className="space-right-top"
          >
            <Alert message="Error" description={errorMessage} type="error" showIcon className="mb-2" />
            <Alert
              message="Info"
              description={
                <CSVLink data={leftTokens} separator="," filename="records-left.csv" target="_blank">
                  Get CSV with left tokens
                </CSVLink>
              }
              type="info"
              showIcon
            />
          </Motion>
        </Portal>
      )}
      {endProcess && (
        <Portal>
          <Motion
            duration={2}
            slide={{ direction: "left", active: true, placement: "bottom" }}
            className="space-right-top"
          >
            <Alert
              message="Success"
              description="All tokens have been drafted/minted"
              type="success"
              showIcon
              closable
              className="notification"
            />
          </Motion>
        </Portal>
      )}
      <ProgressBar
        appear={workStatus !== "prepared"}
        percentDone={percentDone}
        totalTokens={totalTokens}
        processedTokens={processedTokens}
      />
    </div>
  );
};

export default SummaryStep;
