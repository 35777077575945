import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import Group from "@components/arrangement/Group/Group";
import { IChildren } from "@components/interfaces/IChildren";
import "./Hint.scss";

const Hint = ({ children }: IChildren) => {
  return (
    <>
      <Group className="mb-1" alignItems="flex-start">
        <InfoCircleOutlined className="hint-icon" />
        <p className="hint ml-1">{children}</p>
      </Group>
    </>
  );
};

export default Hint;
