import { validate } from "uuid";

import { StaticValidationKeys } from "@services/ApiWrapper";
import { Actions, TagType } from "@services/interfaces/ITokenData";
import { isTrue } from "./isTrueByErrorsLength";

interface ValidationReturn {
  valid: boolean;
  errors: string[];
}

export const VALID_MIME_TYPES = ["image/png", "image/jpg", "image/jpeg", "image/svg", "image/bmp"];

export const validateTitle = (recordIndex: number, title: string): ValidationReturn => {
  const errors: string[] = [];

  //validate title lenght
  const titleLength = title.length;
  if (titleLength <= 0 || titleLength > 1024) {
    errors.push(`[Row ${recordIndex}][title] length should be in range of 1-1024.`);
  }

  return { valid: isTrue(errors), errors };
};

export const validateTagId = (recordIndex: number, tag_id?: string): ValidationReturn => {
  const tagIds = new Set();
  const errors: string[] = [];

  if (!!tag_id) {
    // validate length of tag_id
    const tagIdLength = tag_id.length;
    if (tagIdLength < 1 || tagIdLength > 1024) {
      errors.push(`[Row ${recordIndex}][tag_id] length should be in range of 1-1024`);
    }

    //validate if tag_id is unique
    if (tagIds.has(tag_id)) {
      errors.push(`[Row ${recordIndex}][tag_id][${tag_id}] is already used`);
    } else {
      tagIds.add(tag_id);
    }
  }

  return { valid: isTrue(errors), errors };
};

export const validateTagType = (recordIndex: number, tag_type?: TagType): ValidationReturn => {
  const errors: string[] = [];

  if (!!tag_type && tag_type !== "nfc" && tag_type !== "qr") {
    errors.push(`[Row ${recordIndex}][tag_type] should be 'nfc' or 'qr'. Now its ${tag_type}`);
  }

  return { valid: isTrue(errors), errors };
};

export const validateAction = (recordIndex: number, action: Actions): ValidationReturn => {
  const errors: string[] = [];

  if (action !== "draft" && action !== "mint") {
    errors.push(`[Row ${recordIndex}][action] should be 'draft' or 'mint'. Now its ${action}`);
  }

  return { valid: isTrue(errors), errors };
};

export const validateUUID = (recordIndex: number, uuid?: string): ValidationReturn => {
  const uuids = new Set();
  const errors: string[] = [];

  if (!!uuid) {
    if (!validate(uuid)) {
      errors.push(`[Row ${recordIndex}][uuid][${uuid}] in not valid UUID`);
    }
    if (uuids.has(uuid)) {
      errors.push(`[Row ${recordIndex}][uuid][${uuid}] is already used`);
    } else {
      uuids.add(uuid);
    }
  }

  return { valid: isTrue(errors), errors };
};

export const validateSetId = (recordIndex: number, setId?: string): ValidationReturn => {
  const sets = new Set();
  const errors: string[] = [];

  if (!!setId) {
    if (!validate(setId)) {
      errors.push(`[Row ${recordIndex}][setId] ${setId} in not valid UUID`);
    }
    if (sets.has(setId)) {
      errors.push(`[Row ${recordIndex}][setId] ${setId} is already used`);
    } else {
      sets.add(setId);
    }
  }

  return { valid: isTrue(errors), errors };
};

export const staticValidationMap: Record<StaticValidationKeys, Function> = {
  title: (index: number, title: string) => validateTitle(index + 1, title),
  tag_id: (index: number, tmp_tag_id?: string) => validateTagId(index + 1, tmp_tag_id),
  tag_type: (index: number, tag_type?: "qr" | "nfc") => validateTagType(index + 1, tag_type),
  uuid: (index: number, uuid?: string) => validateUUID(index + 1, uuid),
  set_id: (index: number, setId?: string) => validateSetId(index + 1, setId),
  action: (index: number, action: "mint" | "draft") => validateAction(index + 1, action)
};
