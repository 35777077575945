import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";

import { useCsvContext } from "@contexts/CsvContext";
import { TokenField, useSchemasContext } from "@contexts/SchemasContext";

import { staticTokenFields } from "@services/ApiWrapper";

import DataTable from "./DataTable/DataTable";
import ImportCSV from "./ImportCSV/ImportCSV";
import Group from "@components/arrangement/Group/Group";
import Stack from "@components/arrangement/Stack/Stack";
import NavigatorButtons from "@pages/CreateTokensSteps/NavigatorButtons/NavigatorButtons";
import SchemasSelect from "./SchemasSelect/SchemasSelect";
import CSVErrors from "./CSVErrors/CSVErrors";

import "./UploadTokensDetail.scss";

const UploadCsvStep = () => {
  const navigate = useNavigate();

  const { csvForTable } = useCsvContext();
  const { currentSchema, getSchemas } = useSchemasContext();

  const [csvToDownload, setCsvToDownload] = React.useState<string[][] | null>(null);

  const nextActionDisabled = (csvForTable?.length || 0) === 0;
  const prevActionDiabled = false;

  const goBackHome = () => navigate("/");

  React.useEffect(() => {
    if (!currentSchema) return;

    const dynamicTokenFields = currentSchema.fields.map((field: TokenField) => field.key);
    const data = [...staticTokenFields, ...dynamicTokenFields];

    setCsvToDownload([data]);
  }, [currentSchema]);

  React.useEffect(() => {
    getSchemas();
  }, []);

  const fileName = currentSchema ? `${currentSchema.name}.csv` : "tokens_detail.csv";

  return (
    <div className="step2">
      <div>
        <h2 className="mb-2">Upload tokens detail</h2>
        <Stack className="mb-6">
          <p className="description full-width">
            You have to upload tokens data in a
            <Tooltip placement="right" title="CSV is an excel-like file">
              <b className="ml-1">CSV format,</b>
            </Tooltip>
          </p>
          <p className="description">based od product category you chose</p>
        </Stack>
        <h5 className="mb-2">Follow these steps</h5>
        <Stack rowGap={16} className="mb-6">
          <Group colGap={5}>
            <p className="description lh-2-4">1.</p>
            <SchemasSelect />
          </Group>
          <Group colGap={5}>
            <p className="description lh-2-4">2.</p>
            <Group className={`link ${!currentSchema ? "disabled" : ""}`} colGap={4}>
              <CSVLink data={csvToDownload || []} target="_blank" className={`lh-2-4`} filename={fileName}>
                Download file template
              </CSVLink>
              <DownloadOutlined />
            </Group>
          </Group>
          <p className="description lh-2-4">3. Complete the file with your data</p>
        </Stack>
        <ImportCSV disabled={!currentSchema} />

        <CSVErrors />
      </div>
      {!!csvForTable && csvForTable.length > 0 && (
        <NavigatorButtons
          nextDisabled={
            false
            // nextActionDisabled
          }
          prevDisabled={prevActionDiabled}
          customPrevAction={goBackHome}
        />
      )}
      <div className="mt-8">
        <DataTable />
      </div>
      <NavigatorButtons
        nextDisabled={
          false
          // nextActionDisabled
        }
        prevDisabled={prevActionDiabled}
        customPrevAction={goBackHome}
      />
    </div>
  );
};

export default UploadCsvStep;
