import "./CheckBox.scss";

interface ICheckbox {
  checked: boolean;
  onChange: () => void;
  className?: string;
  testid?: string;
  variant: "terms" | "default";
}

const CheckBox = ({ checked, onChange, className, variant, testid }: ICheckbox) => {
  return (
    <>
      <button
        className={`checkbox${className ? ` ${className}` : ""}`}
        data-testid={testid}
        onClick={(e) => {
          e.stopPropagation();
          onChange();
        }}
      >
        {checked ? (
          variant === "terms" ? (
            <img src="/images/checkbox-checked.svg" alt="checkbox checked" width={24} height={24} />
          ) : (
            <img src="/images/checkbox-checked-default.svg" alt="checkbox checked" width={24} height={24} />
          )
        ) : (
          <img src="/images/checkbox-unchecked.svg" alt="checkbox unchecked" width={24} height={24} />
        )}
      </button>
    </>
  );
};

export default CheckBox;
