import ReactDOM from "react-dom/client";
import { CsvProvider } from "@contexts/CsvContext";
import { ImageProvider } from "@contexts/ImageConext";
import { MintProvider } from "@contexts/MintContext";
import { SeedProvider } from "@contexts/SeedContext";
import { ResetProvider } from "@contexts/ResetContext";
import { BalanceVerifierProvider } from "@contexts/BalanceVerifierContext";
import { SchemasProvider } from "@contexts/SchemasContext";
import App from "./App";
import "antd/dist/antd.dark.less";
import "./scss/lib.scss";
import "./scss/utils.scss";
import "./scss/base.scss";
import "./scss/variables.scss";
import "./scss/mixins.scss";

const rootElement = document.getElementById("root");
const ReactRoot = ReactDOM.createRoot(rootElement!);

ReactRoot.render(
  <SeedProvider>
    <ImageProvider>
      <SchemasProvider>
        <CsvProvider>
          <BalanceVerifierProvider>
            <MintProvider>
              <ResetProvider>
                <App />
              </ResetProvider>
            </MintProvider>
          </BalanceVerifierProvider>
        </CsvProvider>
      </SchemasProvider>
    </ImageProvider>
  </SeedProvider>
);
